<template>
  <el-container>
    <header class="page-header">
      <el-header class="header-menu">
        <head-bar :showCollapse="false" :activeValue="active"></head-bar>
      </el-header>
      <div class="content-header">
        <el-form :inline="true" size="small" label-width="110px">
          <el-form-item label="账户变动状态">
            <el-select clearable v-model="search.searchFields.operation" placeholder="请选择">
              <el-option
                v-for="item in operation_list"
                :key="item.code"
                :label="item.value"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="search-button-container">
          <el-button class="search-button" type="primary" icon="el-icon-search" size="small" @click="getIntegralList(1)">查询</el-button>
          <el-button size="small" @click="clearSearch" plain>重置</el-button>
        </div>
      </div>
    </header>
    <el-container>
      <div class="content">
        <div class="main" ref="main">
          <el-checkbox-group v-model="baIdList">
            <el-card class="item" v-for="(item, index) of list" :key="item.id" :ref="`item${item.id}`">
              <el-descriptions :title="item.name" :column="2" size="small">
                <el-descriptions-item label="变动数量">{{item.operation_msg}}{{item.integral_amount}}</el-descriptions-item>
                <el-descriptions-item label="变动时间">{{formatDate(Number(item.create_time) * 1000, 'YYYY.MM.DD').dateString}}</el-descriptions-item>
              </el-descriptions>
            </el-card>
          </el-checkbox-group>
        </div>
      </div>
    </el-container>
  </el-container>
</template>
<script>
import headBar from '@/components/frames/mobileHeadBar'
import {throttle, formatDate} from '@/libs/utils'
import { BAApi } from '@/api'

export default {
  data: () => ({
    search: {
      searchFields: {
        page: 1,
        count: 20,
        operation: '',
        myList: [],
        hasMore: true,
        noData: false
      }
    },
    operation_list: [
      {
        code: 0,
        value: '全部'
      },
      {
        code: 1,
        value: '入账'
      },
      {
        code: 2,
        value: '出账'
      }
    ],
    list: [],
    active: 'mobileIntegralList',
    updateParams: {},
    baIdList: []
  }),
  components: {
    headBar
  },
  methods: {
    formatDate: formatDate,
    clearSearch() {
      this.search = {
        searchFields: Object.assign({ count: this.search.searchFields.count }, {
          page: 1,
          operation: ''
        })
      }
    },
    // get data
    async getIntegralList(page) {
      this.search.searchFields.page = page || this.search.searchFields.page
      let params = {...this.search.searchFields}
      try {
        let data = (await BAApi.getIntegralList(params)).data
        if (data.code === 0) {
          this.$validator.reset()
          if (this.search.searchFields.page === 1) {
            this.search.searchFields.myList = []
          }
          const newList = data.receipt_list || []
          if (newList.length >= this.search.searchFields.count) {
            this.search.searchFields.hasMore = true
          } else {
            this.search.searchFields.hasMore = false
          }
          this.search.searchFields.myList = this.search.searchFields.myList.concat(newList)
          if (this.search.searchFields.myList.length === 0 && this.search.searchFields.page === 1) {
            this.search.searchFields.noData = true
          } else {
            this.search.searchFields.noData = false
          }
          this.list = this.search.searchFields.myList
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      }
    }
  },

  mounted() {
    window.scrollTo(0, 1)
    this.getIntegralList()
    let that = this
    window.onscroll = throttle(function () {
      const scrollHeight = document.body.scrollHeight
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
      const offsetHeight = document.body.offsetHeight
      if (scrollHeight - (scrollTop + offsetHeight) <= 150 && that.search.searchFields.hasMore) {
        that.search.searchFields.page ++
        that.getIntegralList()
      }
    }, 1000, true)
  },
  beforeDestroy() {
    window.onscroll = null
  }
}
</script>

<style lang="less" scoped>
.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 9;
  .content-header {
    margin-top: 15px;
    overflow: hidden;
    .search-button-container {
      float: right;
      padding-right: 15px;
      .search-button {
        margin-left: 15px;
      }
    }
  }
  .header-menu {
    height: auto!important;
  }
}
.content {
  margin-top: 220px;
  .button-field {
    padding-left: 15px;
  }
  .main {
    padding: 15px;
    .item {
      margin-bottom: 15px;
      .foot {
        height: 25px;
        padding-top: 10px;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: #409EFF;
      }
    }
  }
}
.content-header /deep/ .el-select {
  width: 260px;
}
.content-header /deep/ .el-input {
  width: 260px;
}
.el-menu-demo {
  overflow-x: scroll;
  display: flex;
}
</style>
<style lang="less">
.el-message-box {
  width: 360px;
}
</style>


